import React from 'react';
import s from './Weather.module.scss';
import Rain from './../../assets/images/weather/rain.svg';
import {weatherAPI} from "../../helpers/weatherAPI";

interface IWeather {
    city?: string
    country?: string
    date?: any
    degrees?: any
    humidity?: any
    weatherCode?: any
    feels?: any
    background?: any
}
const Weather = ({city, country, date, degrees, humidity, weatherCode, feels, background}: IWeather) => {
    let weatherData: any = weatherAPI(weatherCode);
    return (
        <div className={s.weather}>
            <div className={s.weather__background}>
                <img src={background} alt=""/>
            </div>
            <div className={s.weather__info}>
                <div className={s.weather__row}>
                    <strong>{city}</strong>
                    <strong>{date.toLocaleString('en-EN', { weekday: 'short' })}</strong>
                </div>
                <div className={s.weather__row}>
                    <span>{country}</span>
                    <span>{("0" + date.getDate()).slice(-2)} {date.toLocaleString('en-EN', { month: 'short' })}</span>
                </div>
            </div>
            <div className={s.info}>
                <div className={s.info__image}>
                    <img src={weatherData.image} alt=""/>
                </div>
                <div className={s.info__info}>
                    <strong>{degrees.toFixed(0)}°</strong>
                    <span>{weatherData.title}</span>
                    <span>Feels like {feels.toFixed(0)}°</span>
                </div>
                <div className={s.info__humidity}>
                    <strong>{humidity}</strong>
                </div>
            </div>
        </div>
    );
};

export default Weather;