import React, {useEffect, useState} from 'react';
import {createBrowserRouter, Route, Router, RouterProvider, Routes, useLocation, useNavigate} from "react-router-dom";
import Home from "./pages/Home/Home";
import Weather from "./pages/Weather/Weather";
import Calendar from "./pages/Calendar/Calendar";
import Album from "./pages/Album/Album";
import {pageAPI} from "./services/PageService";
import {fetchHomeGallery, fetchHomeGalleryData, fetchHomePage} from "./slices/homeSlice";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {fetchCalendarPage} from "./slices/calendarSlice";
import {fetchAlbumPage, fetchImages} from "./slices/albumSlice";
import {fetchHourlyWeather, fetchWeatherImage, fetchWeatherPage} from "./slices/weatherSlice";
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';
import Layout from "./components/Layout/Layout";
import Loader from "./components/Loader/Loader";
import {fetchGlobalSettings, fetchPageTimeouts} from "./slices/globalSlice";

const App = () => {
    let dispatch = useAppDispatch();
    let [fetchArg, setFetchArg] = useState<number>(0);
    let {data: pagesData} = pageAPI.useFetchAllPagesQuery(fetchArg);

    let [pageOrder, setPageOrder] = useState([]);
    let navigate = useNavigate();
    let location = useLocation();
    let isHomeLoading = useAppSelector(state => state.home.isLoading);
    let isWeatherLoading = useAppSelector(state => state.weather.isLoading);
    let isGlobalLoading = useAppSelector(state => state.global.isLoading);
    let pageTimeouts: any = useAppSelector(state => state.global.data.pageTimeouts);

    let updatePages = () => {
        pagesData && setPageOrder(pagesData.items.map((i: any) => ({id: i.id, type: i.meta.type})));
        pagesData && dispatch(fetchPageTimeouts(pagesData.items.map((i: any) => (i.id))));
        pagesData && pagesData.items.forEach(async (p: any) => {
            switch (p.meta.type) {
                case 'home.DashboardPage':
                    await dispatch(fetchHomePage(p.id));
                    await dispatch(fetchHomeGalleryData());
                    await dispatch(fetchHomeGallery());
                    break;
                case 'home.NoticePage':
                    await dispatch(fetchCalendarPage(p.id));
                    break;
                case 'home.AlbumGallery':
                    await dispatch(fetchAlbumPage(p.id));
                    await dispatch(fetchImages());
                    break;
                case 'home.WeatherPage':
                    await dispatch(fetchWeatherPage(p.id));
                    await dispatch(fetchHourlyWeather());
                    await dispatch(fetchWeatherImage());
                    break;
            }
        });
    }
    let startPages = () => {
        let timeout = 0;
        let timeout1: any;
        let timeout2: any;

        pageOrder.forEach((p: any, index) => {
            timeout1 = setTimeout(() => {
                switch (p.type) {
                    case 'home.DashboardPage':
                        navigate('');
                        break;
                    case 'home.NoticePage':
                        navigate('/calendar');
                        break;
                    case 'home.AlbumGallery':
                        navigate('/album');
                        break;
                    case 'home.WeatherPage':
                        navigate('/weather');
                        break;
                }
            }, timeout);
            timeout = timeout + pageTimeouts[p.id] * 1000;
        });

        timeout2 = setTimeout(() => {
            navigate('');
            setFetchArg(new Date().getTime());
            clearTimeout(timeout1);
            clearTimeout(timeout2);
        }, timeout);
    }

    useEffect(() => {
        updatePages();
    }, [pagesData]);

    useEffect(() => {
        if (!isWeatherLoading && !isHomeLoading && !isGlobalLoading) {
            startPages();
        }
    }, [pageOrder, isHomeLoading, isWeatherLoading, isGlobalLoading]);

    useEffect(() => {
        dispatch(fetchGlobalSettings());
    }, []);

    return (

            <>
                {(!isWeatherLoading && !isHomeLoading) ? <Loader isHidden={true}/> :
                    <Loader/>}
                <Layout>
                    <TransitionGroup>
                        <CSSTransition classNames="fade"
                                       timeout={500}
                                       key={location.pathname}>
                            <Routes>
                                <Route path={'/'} element={<Home/>}/>
                                <Route path={'/weather'} element={<Weather/>}/>
                                <Route path={'/calendar'} element={<Calendar/>}/>
                                <Route path={'/album'} element={<Album/>}/>
                            </Routes>
                        </CSSTransition>
                    </TransitionGroup>
                </Layout>
            </>
    );
};

export default App;