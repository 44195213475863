import React, {useEffect, useState} from 'react';
import s from './Album.module.scss';
import Layout from "../../components/Layout/Layout";
import Gallery from "../../components/Gallery/Gallery";
import {useAppSelector} from "../../app/hooks";
import GalleryPhoto from './../../assets/images/gallery.png';

const Album = () => {

    let data: any = useAppSelector(state => state.album.data);
    let [images, setImages] = useState<any>([]);

    useEffect(() => {
        data.images && setImages(Object.values(data.images));
    }, [data]);

    return (
        <div className={s.album}>
            <h1>{data.title}</h1>
            <Gallery items={images} delay={data.imageDuration * 1000}/>
        </div>
    );
};

export default Album;