import React, {useEffect, useState} from 'react';
import s from './Sidebar.module.scss';
import Logo from './../../assets/images/logo.png';
import Sun from './../../assets/images/weather/sun.svg';
import Sunset from './../../assets/images/weather/sunset.svg';
import {useAppSelector} from "../../app/hooks";
import axios from "axios";

const Sidebar = () => {
    let [currentDate, setCurrentDate] = useState<any>(new Date());
    let weather: any = useAppSelector(state => state.weather.hourlyWeather);
    let [sunTime, setSunTime] = useState<any>();
    let globalData: any = useAppSelector(state => state.global.data);

    useEffect(() => {
        setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);
    }, []);

    useEffect(() => {
        let isSunTimeSet = false;
        weather.daily && weather.daily.time.forEach((t: any, index: number) => {
            let date = new Date(t * 1000);
            if (date.getTime() > currentDate.getTime() && !isSunTimeSet) {
                setSunTime({sunrise: weather.daily.sunrise[index - 1], sunset: weather.daily.sunset[index - 1]});
                isSunTimeSet = true;
            }
        });
    }, [weather]);

    return (
        <aside className={s.sidebar}>
            <div className={s.sidebar__logo}>
                {globalData.settings && <img src={globalData.settings.sideBarLogo} alt=""/>}
            </div>
            <div className={s.timer}>
                <span>{("0" + currentDate.getDate()).slice(-2)} {currentDate.toLocaleString('en-EN', { month: 'long' })}, {currentDate.getFullYear()}</span>
                <strong>{currentDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</strong>
                <span>{currentDate.toLocaleString('en-EN', { weekday: 'long' })}</span>
            </div>
            <div className={s.sun}>
                <span>Sun Rise & Sun Set</span>
                { sunTime &&
                    <div className={s.sun__info}>
                        <div className={s['sun__info-item']}>
                            <div className={s['sun__info-item__image']}>
                                <img src={Sun} alt=""/>
                            </div>
                            <div className={s['sun__info-item__info']}>
                                <strong>{new Date(sunTime.sunrise * 1000).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</strong>
                                <span>Sun Rise</span>
                            </div>
                        </div>
                        <div className={s['sun__info-item']}>
                            <div className={s['sun__info-item__image']}>
                                <img src={Sunset} alt=""/>
                            </div>
                            <div className={s['sun__info-item__info']}>
                                <strong>{new Date(sunTime.sunset * 1000).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</strong>
                                <span>Sun Set</span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </aside>
    );
};

export default Sidebar;