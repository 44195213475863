import React, {useEffect, useState} from 'react';
import s from './Home.module.scss';
import Layout from "../../components/Layout/Layout";
import Weather from "../../components/Weather/Weather";
import Lesson from "../../components/Lesson/Lesson";
import Gallery from "../../components/Gallery/Gallery";
import {useAppSelector} from "../../app/hooks";
import WeatherComponent from "../../components/Weather/Weather";
import WeatherItem from "../../components/WeatherItem/WeatherItem";
import {useNavigate} from "react-router-dom";

const Home = () => {
    let data: any = useAppSelector(state => state.home.data);
    let weatherData: any = useAppSelector(state => state.weather.data);
    let weather: any = useAppSelector(state => state.weather.hourlyWeather);
    let [currentFeels, setCurrentFeels] = useState<any>(0);
    let [currentHumidity, setCurrentHumidity] = useState<any>(0);
    let currentDate = new Date();
    let [images, setImages] = useState<any>([]);

    // useEffect(() => {
    //     setInterval(() => {
    //         setCurrentDate(new Date());
    //     }, 1000);
    // }, []);

    useEffect(() => {
        data.images && setImages(Object.values(data.images));
    }, [data]);

    useEffect(() => {
        weather.hourly && weather.hourly.time.forEach((t: any, index: number) => {
            let date = new Date(t * 1000);
            if (date.getTime() > currentDate.getTime()) {
                if (currentFeels == 0) {
                    setCurrentFeels(weather.hourly.apparent_temperature[index - 1]);
                }
                if (currentHumidity == 0) {
                    setCurrentHumidity(weather.hourly.relativehumidity_2m[index - 1]);
                }
            }
        });
    }, [weather]);

    return (
        <div className={s.notices}>
            <div className={s.notices__col}>
                {weatherData.weatherTitle && weather.current_weather &&
                    <Weather city={weatherData.weatherTitle.split(', ')[0]}
                             country={weatherData.weatherTitle.split(', ')[1]}
                             degrees={weather.current_weather.temperature}
                             date={currentDate}
                             weatherCode={weather.current_weather.weathercode}
                             feels={currentFeels}
                             humidity={currentHumidity} background={weatherData.image}/>}
                <Gallery items={images} delay={data.gallery && data.gallery.imageDuration && data.gallery.imageDuration * 1000} isSmall={true}/>
            </div>
            <div className={s.notices__col}>
                <h1>{data.title}</h1>
                <span>{("0" + currentDate.getDate()).slice(-2)} {currentDate.toLocaleString('en-EN', {month: 'long'})}, {new Date().getFullYear()}</span>
                <div className={s.lessons}>
                    {data.announcements && data.announcements.map((n: any) =>
                        <Lesson title={n.value.Title} key={n.id}/>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Home;