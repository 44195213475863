import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";
import {domain} from "../helpers/server";

const initialState = {
    data: {},
    isLoading: true
}

export const fetchCalendarPage = createAsyncThunk(
    'home/fetchCalendarPage',
    async (id: number, thunkAPI) => {
        const response = await axios.get(`${domain}/v2/pages/${id}/?format=json`);
        return response.data;
    }
)

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchCalendarPage.fulfilled, (state, action) => {
            state.data = action.payload
        })
    }
})