import {configureStore, ThunkAction, Action, combineReducers} from '@reduxjs/toolkit';

import {pageAPI} from "../services/PageService";
import {homeSlice} from "../slices/homeSlice";
import {calendarSlice} from "../slices/calendarSlice";
import {weatherSlice} from "../slices/weatherSlice";
import {albumSlice} from "../slices/albumSlice";
import {globalSlice} from "../slices/globalSlice";

const rootReducer = combineReducers({
  [pageAPI.reducerPath]: pageAPI.reducer,
  global: globalSlice.reducer,
  home: homeSlice.reducer,
  calendar: calendarSlice.reducer,
  weather: weatherSlice.reducer,
  album: albumSlice.reducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(pageAPI.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
