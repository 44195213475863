import React from 'react';
import s from './WeatherItem.module.scss';
import classNames from "classnames";
import Rain from './../../assets/images/weather/rain.svg';
import {weatherAPI} from "../../helpers/weatherAPI";

interface IWeatherItem {
    isAlt?: boolean
    date?: any
    degrees?: any
    weatherCode?: any
}
const WeatherItem = ({isAlt, date, degrees, weatherCode}: IWeatherItem) => {
    let weatherData: any = weatherAPI(weatherCode);

    return (
        <div className={classNames(s.weather, isAlt && s.weather_alt)}>
            {!isAlt ? <>
                <span className={s.weather__degrees}>{degrees.toFixed(0)}°</span>
                <div className={s.weather__image}>
                    <img src={weatherData.image} alt=""/>
                </div>
                <span className={s.weather__time}>{date.toLocaleString('en-US', { hour: 'numeric', hour12: true })}</span>
                <span className={s.weather__date}>{("0" + date.getDate()).slice(-2)} {date.toLocaleString('en-EN', { month: 'short' })}</span>
            </> : <>
                {/*<div className={s.weather__humidity} style={{background: "#FF7775"}}>*/}
                {/*    <strong>192</strong>*/}
                {/*</div>*/}
                <span className={s.weather__title}>{weatherData.title}</span>
                <div className={s.weather__image}>
                    <img src={weatherData.image} alt=""/>
                </div>
                <span className={s.weather__degrees}>{degrees.toFixed(0)}°</span>
                <span className={s.weather__date}>{("0" + date.getDate()).slice(-2)} {date.toLocaleString('en-EN', { month: 'short' })}, {date.getFullYear()}</span>
            </>}
        </div>
    );
};

export default WeatherItem;