import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";
import {domain} from "../helpers/server";

const initialState = {
    data: {
        image: <any>'',
    },
    hourlyWeather: {},
    isLoading: true
}

export const fetchWeatherPage = createAsyncThunk(
    'home/fetchWeatherPage',
    async (id: number, thunkAPI) => {
        const response = await axios.get(`${domain}/v2/pages/${id}/?format=json`);
        return response.data;
    }
)

export const fetchHourlyWeather = createAsyncThunk(
    'home/fetchHourlyWeather',
    async (_void, {getState}) => {
        const state: any = getState();
        const location = state.weather.data.weatherLocation.split(',');
        const response = await axios.get(`https://api.open-meteo.com/v1/forecast?latitude=${location[0]}&longitude=${location[1]}&hourly=temperature_2m,relativehumidity_2m,apparent_temperature,weathercode&daily=weathercode,temperature_2m_max,temperature_2m_min,sunrise,sunset&timezone=auto&current_weather=true&timeformat=unixtime`);
        return response.data;
    }
)

export const fetchWeatherImage = createAsyncThunk(
    'home/fetchWeatherImage',
    async (_void, {getState}) => {
        const state: any = getState();
        const id = state.weather.data.mainWeatherBackground.id;
        const response = await axios.post(`${domain}/v3/images`, {images: [id]});
        return Object.values(response.data.images)[0];
    }
)

export const weatherSlice = createSlice({
    name: 'weather',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        // builder.addCase(fetchWeatherPage.pending, (state, action) => {
        //     state.isLoading = true
        // })
        builder.addCase(fetchWeatherPage.fulfilled, (state, action) => {
            state.data = action.payload
        })
        builder.addCase(fetchHourlyWeather.fulfilled, (state, action) => {
            state.hourlyWeather = action.payload
            state.isLoading = false
        })
        builder.addCase(fetchWeatherImage.fulfilled, (state, action) => {
            state.data.image = action.payload
        })
    }
})