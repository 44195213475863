import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {domain} from "../helpers/server";

export const pageAPI = createApi({
    reducerPath: 'pageAPI',
    baseQuery: fetchBaseQuery({baseUrl: `${domain}/v2/pages`}),
    endpoints: (build) => ({
        fetchAllPages: build.query<any, any>({
            query: () => ({
                url: '/?format=json'
            })
        }),
        fetchPage: build.query<any, number>({
            query: (id: number) => ({
                url: `/${id}/?format=json`
            })
        }),
    })
})