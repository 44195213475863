import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";
import {domain} from "../helpers/server";

const initialState = {
    data: {
        settings: {},
        pageTimeouts: {}
    },
    isLoading: true
}

export const fetchGlobalSettings = createAsyncThunk(
    'global/fetchGlobalSettings',
    async (_void, thunkAPI) => {
        const response = await axios.get(`${domain}/v3/global-settings`);
        return response.data;
    }
)

export const fetchPageTimeouts = createAsyncThunk(
    'global/fetchPageTimeouts',
    async (pages: any, thunkAPI) => {
        const response = await axios.post(`${domain}/v3/pageTimeout/`, {pages});
        return response.data;
    }
)

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchGlobalSettings.fulfilled, (state, action) => {
            state.data.settings = {...action.payload.settings}
        })
        builder.addCase(fetchPageTimeouts.fulfilled, (state, action) => {
            state.data.pageTimeouts = action.payload.maxDuration
            state.isLoading = false
        })
    }
})
