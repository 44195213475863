
import Sun from './../assets/images/weather/sun.svg';
import Cloudy from './../assets/images/weather/cloudy.svg';
import Rain from './../assets/images/weather/rain.svg';
import Rain3 from './../assets/images/weather/rain-3.svg';
import Snowstorm from './../assets/images/weather/snowstorm.svg';
import Snowstorm2 from './../assets/images/weather/snowstorm2.svg';
import Sunset from './../assets/images/weather/sunset.svg';
import Thunder from './../assets/images/weather/thunder.svg';
import WeatherApp from './../assets/images/weather/weather-app.svg';

export const weatherAPI = (weatherCode: number) => {
    switch (weatherCode) {

        case 0:
            return {image: Sun, title: 'Clear Sky'}
        case 1:
            return {image: Sun, title: 'Mainly clear'}
        case 2:
            return {image: Cloudy, title: 'Partly cloudy'}
        case 3:
            return {image: Cloudy, title: 'Overcast'}
        case 45:
            return {image: WeatherApp, title: 'Fog'}
        case 48:
            return {image: WeatherApp, title: 'Depositing rime fog'}
        case 51:
            return {image: Rain3, title: 'Light drizzle'}
        case 53:
            return {image: Rain3, title: 'Moderate drizzle'}
        case 55:
            return {image: Rain3, title: 'Dense drizzle'}
        case 56:
            return {image: Rain3, title: 'Light freezing drizzle'}
        case 57:
            return {image: Rain3, title: 'Dense freezing drizzle'}
        case 61:
            return {image: Rain, title: 'Slight rain'}
        case 63:
            return {image: Rain, title: 'Moderate rain'}
        case 65:
            return {image: Rain, title: 'Heavy rain'}
        case 66:
            return {image: Rain, title: 'Light freezing rain'}
        case 67:
            return {image: Rain, title: 'Heavy freezing rain'}
        case 71:
            return {image: Rain, title: 'Slight snow fall'}
        case 73:
            return {image: Rain, title: 'Moderate snow fall'}
        case 75:
            return {image: Rain, title: 'Heavy snow fall'}
        case 77:
            return {image: Rain, title: 'Snow grains'}
        case 80:
            return {image: Rain, title: 'Slight rain showers'}
        case 81:
            return {image: Rain, title: 'Moderate rain showers'}
        case 82:
            return {image: Rain, title: 'Violent rain showers'}
        case 85:
            return {image: Rain, title: 'Slight snow showers'}
        case 86:
            return {image: Rain, title: 'Heavy snow showers'}
        case 95:
            return {image: Thunder, title: 'Thunderstorm'}
        case 96:
            return {image: Thunder, title: 'Thunderstorm with slight hail'}
        case 99:
            return {image: Thunder, title: 'Thunderstorm with heavy hail'}
        default:
            break;
    }
}