import React, {useEffect, useState} from 'react';
import s from './Weather.module.scss';
import Layout from "../../components/Layout/Layout";
import WeatherItem from "../../components/WeatherItem/WeatherItem";
import WeatherComponent from './../../components/Weather/Weather'
import {useAppSelector} from "../../app/hooks";
import axios from 'axios';

const Weather = () => {
    let data: any = useAppSelector(state => state.weather.data);
    let weather: any = useAppSelector(state => state.weather.hourlyWeather);
    let [currentFeels, setCurrentFeels] = useState<any>(0);
    let currentDate = new Date();
    let [currentHumidity, setCurrentHumidity] = useState<any>(0);
    let [hourlyWeather, setHourlyWeather] = useState<any>([]);
    let [dailyWeather, setDailyWeather] = useState<any>([]);

    useEffect(() => {
        let counter = 0;
        let weatherArray: any = [];
        weather.hourly && weather.hourly.time.forEach((t: any, index: number) => {
            let date = new Date(t * 1000);
            if (date.getTime() > currentDate.getTime()) {
                if (currentFeels == 0) {
                    setCurrentFeels(weather.hourly.apparent_temperature[index - 1]);
                }
                if (currentHumidity == 0) {
                    setCurrentHumidity(weather.hourly.relativehumidity_2m[index - 1]);
                }
                if (counter < 3) {
                    weatherArray.push({
                        date: date,
                        degrees: weather.hourly.temperature_2m[index],
                        feels: weather.hourly.apparent_temperature[index],
                        weathercode: weather.hourly.weathercode[index]
                    });
                    counter++;
                }
            }
        });
        setHourlyWeather(weatherArray);

        counter = 0;
        weatherArray = [];
        weather.daily && weather.daily.time.forEach((t: any, index: number) => {
            let date = new Date(t * 1000);
            if (date.getTime() > currentDate.getTime()) {
                if (counter < 4) {
                    weatherArray.push({
                        date: date,
                        degrees: (weather.daily.temperature_2m_max[index] + weather.daily.temperature_2m_min[index]) / 2,
                        weathercode: weather.daily.weathercode[index]
                    });
                    counter++;
                }
            }
        });
        setDailyWeather(weatherArray);
    }, [weather]);

    return (
        data.weatherTitle && weather.current_weather && <div className={s.weather}>
            <h1>{data.title}</h1>
            <div className={s.weather__block}>
                <div className={s.weather__row}>
                    <WeatherComponent city={data.weatherTitle.split(', ')[0]}
                                      country={data.weatherTitle.split(', ')[1]}
                                      degrees={weather.current_weather.temperature}
                                      date={currentDate}
                                      weatherCode={weather.current_weather.weathercode}
                                      feels={currentFeels} background={data.image} humidity={currentHumidity}/>
                    {hourlyWeather.map((w: any) => <WeatherItem degrees={w.degrees} date={w.date}
                                                                weatherCode={w.weathercode} key={w.date}/>)}
                </div>
                <div className={s.weather__row}>
                    {dailyWeather.map((w: any) => <WeatherItem degrees={w.degrees} date={w.date}
                                                               weatherCode={w.weathercode} isAlt={true} key={w.date}/>)}
                </div>
            </div>
        </div>
    );
};

export default Weather;