import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";
import {domain} from "../helpers/server";

const initialState = {
    data: {
        images: []
    },
    isLoading: true
}

export const fetchAlbumPage = createAsyncThunk(
    'home/fetchAlbumPage',
    async (id: number, thunkAPI) => {
        const response = await axios.get(`${domain}/v2/pages/${id}/?format=json`);
        return response.data;
    }
)

export const fetchImages = createAsyncThunk(
    'home/fetchImages',
    async (_void, {getState}) => {
        const state: any = getState();
        const images = state.album.data.image.map((i: any) => i.value);
        const response = await axios.post(`${domain}/v3/images`, {images: images});
        return response.data;
    }
)

export const albumSlice = createSlice({
    name: 'album',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchAlbumPage.fulfilled, (state, action) => {
            state.data = action.payload
        })
        builder.addCase(fetchImages.fulfilled, (state, action) => {
            state.data = {...state.data, ...action.payload}
        })
    }
})